import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import detector from 'i18next-browser-languagedetector';

import { DEFAULT_LANGUAGE, DE } from './consts';
import appDe from './de/app.json';
import homeEn from './en/home.json';
import diagnosisDe from './de/diagnosis.json';
import profileDe from './de/profile.json';

const resources = {
  [DE]: { app: appDe, home: homeEn, diagnosis: diagnosisDe, profile: profileDe },
};

i18n
  // .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    defaultNS: 'app',
    lng: DE,
    fallbackLng: DEFAULT_LANGUAGE,
    keySeparator: false,
    debug: process.env.NODE_ENV !== 'production',
    nsSeparator: ':',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
