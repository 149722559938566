import React, { useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useLoggedIn, useAuth, useUser, useSessionActions, useAlerts, ID } from 'store/session';

import { Spinner } from './styles';

const InitApp = ({ children }) => {
  const indexPage = useMatch('/');
  const isIndexPage = Boolean(indexPage);
  const { restore, logout } = useSessionActions();
  const { id, action, loading, error } = useAlerts(restore);
  const isAuth = useAuth();
  const isLoggedIn = useLoggedIn();
  const userId = useUser(ID);
  const shouldRestore = isAuth && !isLoggedIn && !userId;

  useEffect(() => {
    if (shouldRestore) action();
  }, [action, shouldRestore]);

  useEffect(() => {
    if (error) logout();
  }, [error, logout]);

  if (shouldRestore && (!id || loading) && !isIndexPage) return <Spinner />;

  return children;
};

InitApp.propTypes = { children: PropTypes.element.isRequired };

export default InitApp;
