export const FEATURE_NAME = 'session';

export const AUTH = 'auth';
export const LOGGED_IN = 'loggedIn';
export const VIEW = 'view';
export const USER = 'user';
export const ADMIN = 'admin';

export const STATUS = 'status';
export const FIRST_NAME = 'firstname';
export const LAST_NAME = 'lastname';
export const TITLE = 'title';
export const DEPARTMENT = 'department';
export const MOBILE = 'mobile';
export const USER_ID = 'id';
export const NEXT_PASSWORD = 'nextPassword';
export const MESSAGE = 'message';
