import { path, pathOr } from 'ramda';

import { FEATURE_NAME as $, STEP, AGE, GENDER, LIST, RESULT, FILTERS } from './consts';

export const getStep = path([$, STEP]);
export const getAge = path([$, AGE]);
export const getGender = path([$, GENDER]);
export const getAttributes = path([$, LIST]);
export const getAttribute = (id) => path([$, LIST, id]);
export const getResult = path([$, RESULT]);
export const getResultByScore = (score) => pathOr(null, [$, RESULT, score]);
export const getFilters = path([$, FILTERS]);
export const getFilter = (name) => path([$, FILTERS, name]);
