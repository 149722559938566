import { lazy } from 'react';
import { matchPath } from 'react-router-dom';
import { cond, pipe, map, propOr, flatten, T } from 'ramda';

import ExternalLayout from './ExternalLayout';
import InternalLayout from './InternalLayout';

export const ROUTES = [
  {
    key: 'index',
    layout: null,
    items: [
      {
        path: '/',
        component: lazy(() => import('pages/Home')),
        title: 'Home',
        meta: {
          title: 'Home',
          description: 'Beyond the electronic health record system – elevating physician workflow',
        },
      },
    ],
  },
  {
    key: 'external',
    layout: ExternalLayout,
    items: [
      {
        path: '/login',
        component: lazy(() => import('pages/Login')),
        title: 'Login',
        meta: {
          title: 'Login',
          withoutIndexing: true,
        },
      },
      {
        path: '/reset',
        component: lazy(() => import('pages/ResetPassword')),
        title: 'Reset password',
        meta: {
          title: 'Reset password',
          withoutIndexing: true,
        },
      },
      {
        path: '/setpassword',
        component: lazy(() => import('pages/SetPassword')),
        title: 'Set password',
        meta: {
          title: 'Set password',
          withoutIndexing: true,
        },
      },
    ],
  },
  {
    key: 'internal',
    layout: InternalLayout,
    items: [
      {
        path: '/diagnosis',
        component: lazy(() => import('pages/Diagnosis')),
        title: 'Recommendations',
        isPrivate: true,
        meta: {
          title: 'Recommendations',
          withoutIndexing: true,
        },
      },
      {
        path: '/profile',
        component: lazy(() => import('pages/Profile')),
        title: 'Profile',
        isPrivate: true,
        meta: {
          title: 'Profile',
          withoutIndexing: true,
        },
      },
    ],
  },
];

export const routesMapping = cond([
  ...pipe(
    map(propOr([], 'items')),
    flatten,
    map((route) => [(currentPath) => matchPath(route.path, currentPath), () => route])
  )(ROUTES),
  [T, () => ({})],
]);
