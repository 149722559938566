import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Header = styled.header`
  display: grid;
  grid-template-columns: 4fr 6fr;
  align-items: center;
  position: sticky;
  inset: 0 0 auto 0;
  height: var(--header-height);
  min-height: var(--header-height);
  background-color: rgba(var(--color-white-rgb), 0.5);
  z-index: 10;
`;

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font: var(--font22);
  color: var(--color-grey34);
  text-decoration: none;
  padding: 0 15px;
`;

const titleStyle = `
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--color-grey34);
  font: var(--font22);
  color: var(--color-white);
  padding: 0 25px;
`;

export const Title = styled.h1`
  ${titleStyle}
`;

export const Background = styled.div`
  overflow: hidden;
  position: fixed;
  inset: 0;
  background-color: var(--color-body);
  overflow: hidden;
`;

export const Dot = styled.div`
  width: 40vw;
  max-width: 70vh;
  min-width: 500px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  position: absolute;
  inset: ${({ $inset }) => $inset};
  background-color: rgba(var(--color-orange-rgb), 0.7);
  filter: blur(150px);
  will-change: filter;
  transform: scale(${({ $scale }) => $scale}) translate(-50%, -50%);
  @media (orientation: portrait) {
    width: 40vh;
    max-width: 70vw;
  }
`;

export const Main = styled.main`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: 4fr 6fr;
  z-index: 1;
`;

export const CircleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: var(--header-height);
`;

export const Circle = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 17px 17px var(--color-orange);
  background-color: var(--color-orange);
  &:before {
    content: '';
    display: block;
    width: 50%;
    height: 100%;
    background-color: var(--color-white);
  }
`;

export const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(var(--color-white-rgb), 0.2);
  padding: var(--header-height) 25px;
`;

export const Footer = styled.footer`
  flex: 0 1 auto;
  display: grid;
  grid-template-columns: 4fr 6fr;
  height: var(--header-height);
  min-height: var(--header-height);
  z-index: 1;
  &:before {
    content: '';
  }
`;

export const FooterLink = styled(Link)`
  ${titleStyle}
  opacity: 0.5;
  text-decoration: none;
  transition: opacity 0.25s;
  @media (hover) {
    &:hover {
      opacity: 0.8;
    }
  }
  &:active {
    opacity: 1;
  }
`;
