import React from 'react';
import { Routes, Route, Navigate, useMatch } from 'react-router-dom';

import { ROUTES } from './utils';
import Helmet from './Helmet';
import GlobalStyles from './styles';
import InitApp from './InitApp';
import SuspenseComponent from './SuspenseComponent';

const Root = () => {
  const indexPage = useMatch('/');

  return (
    <>
      <Helmet />
      <GlobalStyles $isIndex={Boolean(indexPage)} />
      <InitApp>
        <Routes>
          {ROUTES.map(({ key, layout: Layout, items }) =>
            Layout ? (
              <Route key={key} element={<Layout />}>
                {items.map(({ path, isPrivate, component }) => (
                  <Route key={path} path={path} element={<SuspenseComponent component={component} isPrivate={isPrivate} />} />
                ))}
              </Route>
            ) : (
              items.map(({ path, component }) => (
                <Route
                  key={path}
                  path={path !== '/' ? path : undefined}
                  index={path === '/'}
                  element={<SuspenseComponent component={component} />}
                />
              ))
            )
          )}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </InitApp>
    </>
  );
};

export default Root;
