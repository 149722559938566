/* eslint-disable no-console */
import { put, cancelled } from 'redux-saga/effects';
import { createAction } from 'redux-actions';
import { propOr, path, applySpec, pipe, map, prop, filter, head, not, isEmpty, when } from 'ramda';
import axios from 'axios';

import { DEFAULT_ERROR } from 'api/consts';

import { SUCCESS, ERROR, LOADING, META, TASK_ID, SOURCE } from './consts';
import { setLoading, setSuccess, setError, setCancel } from './actions';

export const withAlert = (saga) =>
  function* wrapper(action) {
    const id = path([META, TASK_ID], action);
    const source = path([META, SOURCE], action);
    const type = prop('type', action);

    yield put(setLoading(id));

    try {
      const data = yield saga(action);
      const success = data?.success;
      const error = data?.error;

      if (error && process.env.REACT_APP_TYPE !== 'production') console.error('sagaHandlerError: ', { type, id, error });

      yield error ? put(setError({ id, error })) : put(setSuccess({ id, success }));
    } catch (error) {
      if (error && process.env.REACT_APP_TYPE !== 'production') console.error('sagaHandlerError: ', { type, id, error });

      yield put(setError({ id, error: propOr(DEFAULT_ERROR, 'message')(error) }));
    } finally {
      const isCancelled = yield cancelled();

      if (isCancelled) yield put(setCancel(id));
      if (isCancelled && source?.cancel) source.cancel('Request was canceled by sagaHandler.');
    }
  };

let counter = 0;
export const createAlertAction = (type) =>
  createAction(
    type,
    ($) => $,
    () => {
      counter += 1;
      return { [TASK_ID]: counter, [SOURCE]: axios.CancelToken.source() };
    }
  );

export const applyCancelToken = (action = {}) =>
  pipe(
    path([META, SOURCE]),
    when(Boolean, (source) => ({ cancelToken: source.token }))
  )(action);

export const mergeAlerts = (alerts) =>
  applySpec({
    [ERROR]: pipe(map(prop(ERROR)), filter(Boolean), head),
    [SUCCESS]: pipe(map(prop(SUCCESS)), filter(not), isEmpty),
    [LOADING]: pipe(map(prop(LOADING)), filter(Boolean), head),
  })(alerts);
