import { throttle, takeEvery, takeLatest, call, select, put } from 'redux-saga/effects';
import { propOr, pick, keys, pathOr } from 'ramda';

import api from 'api';
import { withAlert, applyCancelToken } from 'store/alerts';

import { FETCH_ALL, CREATE, FETCH, SAVE, CREATE_SCORE, FETCH_STACKS, FETCH_STACK } from './types';
import { setFilters, setAttributes } from './actions';
import { ID, FEATURE_NAME, AGE, GENDER, LIST, ATTRIBUTES, RESULT, STEP, SCORE, HITS, FILTERS, EXPAND } from '.';

function* fetchAttributes({ payload }) {
  return { success: yield call(api.get, '/attributes', { params: payload }) };
}

function* createAttribute({ payload }) {
  return { success: yield call(api.post, '/attributes', payload) };
}

function* fetchAttribute({ payload, ...rest }) {
  return { success: yield call(api.get, `/attributes/${payload}`, applyCancelToken(rest)) };
}

function* saveAttribute({ payload, ...rest }) {
  return { success: yield call(api.patch, `/attributes/${payload[ID]}`, payload, applyCancelToken(rest)) };
}

function* createScore() {
  const attrsData = yield select(propOr({}, FEATURE_NAME));
  const attrs = keys(attrsData[LIST] || {});
  const filter = pathOr('', [FILTERS, HITS], attrsData);

  if (!attrs.length) {
    yield put(setFilters());
    yield put(setAttributes({ [RESULT]: null, [STEP]: 1 }));

    return { success: true };
  }

  const data = yield call(api.post, '/stacks/scores', { ...pick([AGE, GENDER], attrsData), [ATTRIBUTES]: attrs });
  const { result, needResetFilter } = data.reduce(
    (acc, item) => {
      const score = String(item[SCORE]);

      if (filter === score) acc.needResetFilter = false;
      if (acc.result[score]) {
        acc.result[score].push(item);
      } else {
        acc.result[score] = [item];
      }

      return acc;
    },
    { result: {}, needResetFilter: true }
  );

  if (needResetFilter) yield put(setFilters({ [HITS]: '' }));

  yield put(setAttributes({ [RESULT]: result, [STEP]: 2 }));

  return { success: true };
}

function* fetchStacks({ payload }) {
  return { success: yield call(api.get, '/stacks', { params: payload }) };
}

function* fetchStack({ payload }) {
  const data = yield call(api.get, `/stacks/${payload}`, { params: { [EXPAND]: [ATTRIBUTES] } });

  return { success: data };
}

export default function* watchAttributes() {
  yield throttle(500, FETCH_ALL, withAlert(fetchAttributes));
  yield takeEvery(CREATE, withAlert(createAttribute));
  yield takeLatest(FETCH, withAlert(fetchAttribute));
  yield takeLatest(SAVE, withAlert(saveAttribute));
  yield throttle(500, CREATE_SCORE, withAlert(createScore));
  yield throttle(500, FETCH_STACKS, withAlert(fetchStacks));
  yield takeLatest(FETCH_STACK, withAlert(fetchStack));
}
