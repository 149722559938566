import { takeLatest, takeLeading, call, put, select } from 'redux-saga/effects';
import sha256 from 'crypto-js/sha256';

import { withAlert, applyCancelToken } from 'store/alerts';
import api from 'api';

import { authenticate, updateUser } from './actions';
import { getUserId } from './selectors';
import { LOGIN, RESTORE, FETCH_RESET_LINK, RESET_PASSWORD, UPDATE_PASSWORD, SAVE_USER, SEND_CONTACT } from './types';
import { PASSWORD, NEXT_PASSWORD } from '.';

const baseURL = process.env.REACT_APP_URL;

function* login({ payload, rest }) {
  const data = yield call(
    api.post,
    '/login',
    {
      ...payload,
      [PASSWORD]: sha256(payload[PASSWORD]).toString(),
    },
    { baseURL, ...applyCancelToken(rest) }
  );

  if (!data?.error) yield put(authenticate(data));

  return data;
}

function* restore(action) {
  const data = yield call(api.get, '/session', { baseURL, ...applyCancelToken(action) });

  yield put(authenticate(data));
}

function* fetchResetLink({ payload, rest }) {
  return yield call(api.post, '/reset', payload, { baseURL, ...applyCancelToken(rest) });
}

function* resetPassword({ payload, rest }) {
  return yield call(
    api.post,
    '/setpassword',
    {
      ...payload,
      [PASSWORD]: sha256(payload[PASSWORD]).toString(),
    },
    { baseURL, ...applyCancelToken(rest) }
  );
}

function* updatePassword({ payload }) {
  return yield call(
    api.post,
    '/updatepassword',
    {
      [PASSWORD]: sha256(payload[PASSWORD]).toString(),
      [NEXT_PASSWORD]: sha256(payload[NEXT_PASSWORD]).toString(),
    },
    { baseURL }
  );
}

function* saveUser({ payload, ...rest }) {
  const id = yield select(getUserId);
  const data = yield call(api.patch, `/users/${id}`, payload, applyCancelToken(rest));

  yield put(updateUser(data));
}

function* sendContact({ payload }) {
  const { success } = yield call(api.post, '/contacts', payload);

  return { success };
}

export default function* watchSession() {
  yield takeLatest(LOGIN, withAlert(login));
  yield takeLatest(RESTORE, withAlert(restore));
  yield takeLatest(FETCH_RESET_LINK, withAlert(fetchResetLink));
  yield takeLatest(RESET_PASSWORD, withAlert(resetPassword));
  yield takeLeading(UPDATE_PASSWORD, withAlert(updatePassword));
  yield takeLatest(SAVE_USER, withAlert(saveUser));
  yield takeLeading(SEND_CONTACT, withAlert(sendContact));
}
