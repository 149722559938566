import React, { useEffect } from 'react';
import { Helmet as DefHelmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { usePageData, filterMeta } from './utils';

const Helmet = () => {
  const { i18n } = useTranslation();
  const { title, description, url, withoutIndexing } = usePageData();

  useEffect(() => {
    filterMeta.forEach((name) => {
      const selectors = document.querySelectorAll(name);

      selectors.forEach((elem) => {
        const fromHelmet = elem.getAttribute('data-react-helmet');

        if (!fromHelmet && selectors.length === 2) elem.remove();
      });
    });
  }, []);

  return (
    <DefHelmet titleTemplate="yoond | %s">
      <html lang={i18n.language} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {withoutIndexing ? <meta name="robots" content="none" /> : <meta name="robots" content="follow,index" />}
      <link rel="canonical" href={url} />
    </DefHelmet>
  );
};

export default Helmet;
