import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { useSessionActions, useView, ADMIN, USER } from 'store/session';
import { useAttributesActions } from 'store/attributes';

import { ReactComponent as UndoneIcon } from './undon.svg';
import { GlobalStyles, Header, Main, Footer, Button, ArrowIcon } from './styles';

const InternalLayout = () => {
  const { t } = useTranslation();
  const view = useView();
  const { setView } = useSessionActions();
  const changeView = useCallback(() => setView(view === ADMIN ? USER : ADMIN), [setView, view]);
  const { reset } = useAttributesActions();
  const onReset = useCallback(() => reset(), [reset]);

  return (
    <>
      <GlobalStyles />
      <Header>{t(view === ADMIN ? 'Expert view' : 'Resident view')}</Header>
      <Main>
        <Outlet />
      </Main>
      <Footer $isReversed={view === ADMIN}>
        <Button type="button" title={t('Undone')} onClick={onReset}>
          <UndoneIcon />
        </Button>
        <Button type="button" title={t('Change view')} onClick={changeView} $isReversed={view === ADMIN}>
          <ArrowIcon $isReversed={view === ADMIN} />
        </Button>
      </Footer>
    </>
  );
};

export default InternalLayout;
