import { path } from 'ramda';

import { ID } from 'api/consts';
import { FEATURE_NAME as $, AUTH, LOGGED_IN, USER, VIEW } from './consts';

export const getAuth = path([$, AUTH]);
export const getLoggedIn = path([$, LOGGED_IN]);
export const getUser = path([$, USER]);
export const getUserProp = (name) => path([$, USER, name]);
export const getUserId = path([$, USER, ID]);
export const getView = path([$, VIEW]);
