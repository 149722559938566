import { useCallback, useEffect, useRef } from 'react';
import { pipe, path } from 'ramda';

import { useAsyncState } from 'utils/hooks';
import { wrapActions, wrapSelectorWithArg } from 'store/utils';

import { META, TASK_ID } from './consts';
import * as actions from './actions';
import { getSuccess, getError, getLoading } from './selectors';

export const useAlertsActions = wrapActions({ ...actions });

export const useSuccess = wrapSelectorWithArg(getSuccess);
export const useError = wrapSelectorWithArg(getError);
export const useLoading = wrapSelectorWithArg(getLoading);

export const useAlerts = (actionCreator) => {
  const index = useRef();
  const [id, setId] = useAsyncState();

  const success = useSuccess(id);
  const error = useError(id);
  const loading = useLoading(id);
  const { setCancel } = useAlertsActions();

  const extractId = useCallback(
    (action) =>
      pipe(path([META, TASK_ID]), (value) => {
        index.current = value;
        setId(value);
      })(action),
    [setId]
  );

  const resetAlerts = useCallback(() => {
    if (!index.current) return;
    setId();
    setCancel(index.current);
    index.current = null;
  }, [setCancel, setId]);

  const handleAction = useCallback((...args) => extractId(actionCreator(...args)), [actionCreator, extractId]);

  useEffect(
    () => () => {
      if (index.current) setCancel(index.current);
    },
    [setCancel]
  );

  return {
    id,
    setId,
    action: handleAction,
    extractId,
    success: id && success,
    error: id && error,
    loading: id && loading,
    resetAlerts,
  };
};
