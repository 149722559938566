export const FEATURE_NAME = 'attributes';

export const STEP = 'step';
export const AGE = 'age';
export const GENDER = 'gender';
export const LIST = 'list';
export const RESULT = 'result';
export const MALE = 'male';
export const FEMALE = 'female';
export const ATTRIBUTES = 'attributes';
export const AGE_FROM = 'ageFrom';
export const AGE_TO = 'ageTo';

export const FILTERS = 'filters';
export const HITS = 'hits';
export const SHOW_STRONG = 'showStrong';
export const SHOW_COMMON = 'showCommon';

export const KIND = 'kind';
export const GROUP = 'group';

export const SCORE = 'score';
