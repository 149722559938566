import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const AUTHENTICATE = getName('AUTHENTICATE');
export const UPDATE_USER = getName('UPDATE_USER');
export const SET_VIEW = getName('SET_VIEW');
export const LOGOUT = getName('LOGOUT');

export const LOGIN = getName('LOGIN');
export const RESTORE = getName('RESTORE');
export const FETCH_RESET_LINK = getName('FETCH_RESET_LINK');
export const RESET_PASSWORD = getName('RESET_PASSWORD');
export const UPDATE_PASSWORD = getName('UPDATE_PASSWORD');
export const SAVE_USER = getName('SAVE_USER');
export const SEND_CONTACT = getName('SEND_CONTACT');
