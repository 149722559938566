export const DEFAULT_ERROR = 'Ups, da ist etwas schief gelaufen.';

export const TOKEN = 'token';
export const ID = '_id';
export const PASSWORD = 'password';
export const EMAIL = 'email';
export const NAME = 'name';

export const SEARCH = 'search';
export const LIMIT = 'limit';
export const START_AFTER = 'startAfter';
export const COUNT = 'count';
export const HAS_MORE = 'hasMore';

export const EXPAND = 'expand';
