import { createGlobalStyle } from 'styled-components';

import WotfardRegularWoff from 'assets/fonts/Wotfard-Regular.woff';
import WotfardRegularWoff2 from 'assets/fonts/Wotfard-Regular.woff2';
import KernStandardRegularWoff from 'assets/fonts/Kern-Standard-Regular.woff';
import KernStandardRegularWoff2 from 'assets/fonts/Kern-Standard-Regular.woff2';
import KernExpandedRegularWoff from 'assets/fonts/Kern-Expanded-Regular.woff';
import KernExpandedRegularWoff2 from 'assets/fonts/Kern-Expanded-Regular.woff2';
import KernExpandedItalicWoff from 'assets/fonts/Kern-Expanded-Italic.woff';
import KernExpandedItalicWoff2 from 'assets/fonts/Kern-Expanded-Italic.woff2';
import KernExtendedMediumWoff from 'assets/fonts/Kern-Extended-Medium.woff';
import KernExtendedMediumWoff2 from 'assets/fonts/Kern-Extended-Medium.woff2';
import KernExtendedBoldWoff from 'assets/fonts/Kern-Extended-Bold.woff';
import KernExtendedBoldWoff2 from 'assets/fonts/Kern-Extended-Bold.woff2';
import KernCondensedMediumWoff from 'assets/fonts/Kern-Condensed-Medium.woff';
import KernCondensedMediumWoff2 from 'assets/fonts/Kern-Condensed-Medium.woff2';
import KernExpandedMediumWoff from 'assets/fonts/Kern-Expanded-Medium.woff';
import KernExpandedMediumWoff2 from 'assets/fonts/Kern-Expanded-Medium.woff2';
import KernExpandedBlackWoff from 'assets/fonts/Kern-Expanded-Black.woff';
import KernExpandedBlackWoff2 from 'assets/fonts/Kern-Expanded-Black.woff2';
import KernCompressedLightWoff from 'assets/fonts/Kern-Compressed-Light.woff';
import KernCompressedLightWoff2 from 'assets/fonts/Kern-Compressed-Light.woff2';
import KernExpandedBoldWoff from 'assets/fonts/Kern-Expanded-Bold.woff';
import KernExpandedBoldWoff2 from 'assets/fonts/Kern-Expanded-Bold.woff2';
import KernStandardBoldWoff from 'assets/fonts/Kern-Standard-Bold.woff';
import KernStandardBoldWoff2 from 'assets/fonts/Kern-Standard-Bold.woff2';

export default createGlobalStyle`
  @font-face {
    font-display: swap;
    font-family: Wotfard-Regular;
    src: url(${WotfardRegularWoff}) format("woff"), url(${WotfardRegularWoff2}) format("woff2");
  }
  @font-face {
    font-display: swap;
    font-family: Kern-Standard-Regular;
    src: url(${KernStandardRegularWoff}) format("woff"), url(${KernStandardRegularWoff2}) format("woff2");
  }
  @font-face {
    font-display: swap;
    font-family: Kern-Expanded-Regular;
    src: url(${KernExpandedRegularWoff}) format("woff"), url(${KernExpandedRegularWoff2}) format("woff2");
  }
  @font-face {
    font-display: swap;
    font-family: Kern-Expanded-Italic;
    src: url(${KernExpandedItalicWoff}) format("woff"), url(${KernExpandedItalicWoff2}) format("woff2");
  }
  @font-face {
    font-display: swap;
    font-family: Kern-Extended-Medium;
    src: url(${KernExtendedMediumWoff}) format("woff"), url(${KernExtendedMediumWoff2}) format("woff2");
  }
  @font-face {
    font-display: swap;
    font-family: Kern-Extended-Bold;
    src: url(${KernExtendedBoldWoff}) format("woff"), url(${KernExtendedBoldWoff2}) format("woff2");
  }
  @font-face {
    font-display: swap;
    font-family: Kern-Condensed-Medium;
    src: url(${KernCondensedMediumWoff}) format("woff"), url(${KernCondensedMediumWoff2}) format("woff2");
  }
  @font-face {
    font-display: swap;
    font-family: Kern-Expanded-Medium;
    src: url(${KernExpandedMediumWoff}) format("woff"), url(${KernExpandedMediumWoff2}) format("woff2");
  }
  @font-face {
    font-display: swap;
    font-family: Kern-Expanded-Black;
    src: url(${KernExpandedBlackWoff}) format("woff"), url(${KernExpandedBlackWoff2}) format("woff2");
  }
  @font-face {
    font-display: swap;
    font-family: Kern-Compressed-Light;
    src: url(${KernCompressedLightWoff}) format("woff"), url(${KernCompressedLightWoff2}) format("woff2");
  }
  @font-face {
    font-display: swap;
    font-family: Kern-Expanded-Bold;
    src: url(${KernExpandedBoldWoff}) format("woff"), url(${KernExpandedBoldWoff2}) format("woff2");
  }
  @font-face {
    font-display: swap;
    font-family: Kern-Standard-Bold;
    src: url(${KernStandardBoldWoff}) format("woff"), url(${KernStandardBoldWoff2}) format("woff2");
  }
  :root {
    --color-black: #000;
    --color-white: #fff;
    --color-white-rgb: 255, 255, 255;
    
    --color-grey34: #343434;
    --color-grey34-rgb: 52, 52, 52;
    --color-grey89: #898989;
    --color-orange: #ff5c00;
    --color-orange-rgb: 255, 92, 0;
    --color-green: #1ed800;
    --color-body: #e2e8e2;
    --color-blue: #0049ff;

    --font50: 400 50px "Wotfard-Regular", sans-serif;
    --font42: 400 42px "Wotfard-Regular", sans-serif;
    --font22: 400 22px "Wotfard-Regular", sans-serif;
    --font20: 400 20px "Wotfard-Regular", sans-serif;
    --font18: 400 18px "Wotfard-Regular", sans-serif;
    --font17: 400 17px "Wotfard-Regular", sans-serif;
    --font15: 400 15px "Wotfard-Regular", sans-serif;
    --font14: 400 14px "Wotfard-Regular", sans-serif;
    --font12: 400 12px "Wotfard-Regular", sans-serif;
    --font10: 400 10px "Wotfard-Regular", sans-serif;
    --font8: 400 8px "Wotfard-Regular", sans-serif;

    --kernStandard30: 400 30px "Kern-Standard-Regular", sans-serif;
    --kernStandardBold30: 700 30px "Kern-Standard-Bold", sans-serif;

    --kernExpanded133: 400 133px "Kern-Expanded-Regular", sans-serif;
    --kernExpandedMedium70: 500 70px "Kern-Expanded-Medium", sans-serif;
    --kernExpandedBlack70: 500 70px "Kern-Expanded-Black", sans-serif;
    --kernExpandedMedium68: 500 68px "Kern-Expanded-Medium", sans-serif;
    --kernExpandedBold68: 700 68px "Kern-Expanded-Bold", sans-serif;
    --kernExpandedItal55: 400 55px "Kern-Expanded-Italic", sans-serif;
    --kernExpanded55: 400 55px "Kern-Expanded-Regular", sans-serif;
    --kernExpanded51: 400 51px "Kern-Expanded-Regular", sans-serif;
    --kernExpanded43: 400 33px "Kern-Expanded-Regular", sans-serif;
    --kernExpandedBlack40: 900 40px "Kern-Expanded-Black", sans-serif;
    --kernExpandedItal33: 400 33px "Kern-Expanded-Italic", sans-serif;
    --kernExpanded33: 400 33px "Kern-Expanded-Regular", sans-serif;
    --kernExpandedMedium33: 500 33px "Kern-Expanded-Medium", sans-serif;
    --kernExpandedBlack33: 500 33px "Kern-Expanded-Black", sans-serif;
    --kernExpandedItal30: 400 30px "Kern-Expanded-Italic", sans-serif;
    --kernExpandedMedium30: 500 30px "Kern-Expanded-Medium", sans-serif;
    --kernExpanded26: 400 26px "Kern-Expanded-Regular", sans-serif;
    --kernExpandedBlack23: 900 23px "Kern-Expanded-Black", sans-serif;
    --kernExpandedItal20: 400 20px "Kern-Expanded-Italic", sans-serif;
    --kernExpandedMedium20: 500 20px "Kern-Expanded-Medium", sans-serif;

    --kernExtendedBold30: 700 30px "Kern-Extended-Bold", sans-serif;
    --kernExtendedMedium30: 500 30px "Kern-Extended-Medium", sans-serif;
    --kernExtendedMedium23: 500 23px "Kern-Extended-Medium", sans-serif;
    --kernExtendedBold20: 700 20px "Kern-Extended-Bold", sans-serif;
    --kernExtendedMedium20: 500 20px "Kern-Extended-Medium", sans-serif;
    --kernExtendedBold15: 700 15px "Kern-Extended-Bold", sans-serif;

    --kernCondensedMedium40: 500 40px "Kern-Condensed-Medium", sans-serif;
    --kernCondensedMedium30: 500 30px "Kern-Condensed-Medium", sans-serif;
    --kernCondensedMedium23: 500 23px "Kern-Condensed-Medium", sans-serif;
    --kernCondensedMedium20: 500 20px "Kern-Condensed-Medium", sans-serif;
    --kernCondensedMedium14: 500 14px "Kern-Condensed-Medium", sans-serif;

    --kernCompressedLight20: 300 20px "Kern-Compressed-Light", sans-serif;
    --kernCompressedLight10: 300 10px "Kern-Compressed-Light", sans-serif;
    
    --header-height: 50px;
    --header-height-mobile: 24px;

    --vh: 1dvh;

    @supports not (height: 1dvh) {
      --vh: 1vh;
    }
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: currentColor;
    -webkit-overflow-scrolling: touch;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }
  html {
    height: 100%;
    min-width: ${({ $isIndex }) => ($isIndex ? 350 : 1024)}px;
    display: flex;
    flex-direction: column;
  }
  body, #root {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  button, select {
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    &:disabled {
      -webkit-text-fill-color: currentColor;
      opacity: 1;
      cursor: not-allowed;
    }
  }
  input, textarea {
    outline: none;
    border: none;
    background: none;
    &:disabled {
      -webkit-text-fill-color: currentColor;
      opacity: 1;
      cursor: not-allowed;
    }
  }
`;
