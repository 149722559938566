import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const SET_ATTRIBUTES = getName('SET_ATTRIBUTES');
export const SET_FILTERS = getName('SET_FILTERS');
export const TOGGLE_ATTRIBUTE = getName('TOGGLE_ATTRIBUTE');
export const RESET = getName('RESET');

export const FETCH_ALL = getName('FETCH_ALL');
export const CREATE = getName('CREATE');
export const FETCH = getName('FETCH');
export const SAVE = getName('SAVE');
export const CREATE_SCORE = getName('CREATE_SCORE');
export const FETCH_STACKS = getName('FETCH_STACKS');
export const FETCH_STACK = getName('FETCH_STACK');
