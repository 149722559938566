import { useRef, useState, useEffect, useCallback } from 'react';

export const useAsyncState = (init) => {
  const isMounted = useRef(false);
  const [state, setState] = useState(init);
  const checkMounted = useCallback(() => Boolean(isMounted.current), []);

  const handleChange = useCallback((value) => {
    if (!isMounted.current) return;

    setState(value);
  }, []);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return [state, handleChange, checkMounted];
};
