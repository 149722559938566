import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, matchPath, Outlet } from 'react-router-dom';
import { cond, T } from 'ramda';

import { DOTS } from './consts';
import { Header, NavLink, Title, Background, Dot, Main, CircleWrap, Circle, Content, Footer, FooterLink } from './styles';

const ExternalLayout = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [title, fLink] = useMemo(() => {
    const check = (path) => (current) => Boolean(matchPath(path, current));

    return cond([
      [check('/login'), () => ['Login', { to: '/', name: 'Register' }]],
      [check('/reset'), () => ['Reset password', { to: '/login', name: 'Login' }]],
      [check('/setpassword'), () => ['Set new password', { to: '/login', name: 'Login' }]],
      [T, () => ['', { to: '/login', name: 'Login' }]],
    ])(pathname);
  }, [pathname]);

  return (
    <>
      <Header>
        <NavLink to="/">yoond</NavLink>
        <Title>{t(title)}</Title>
      </Header>
      <Background>
        {DOTS.map(({ id, scale, inset }) => (
          <Dot key={id} $scale={scale} $inset={inset} />
        ))}
      </Background>
      <Main>
        <CircleWrap>
          <Circle />
        </CircleWrap>
        <Content>
          <Outlet />
        </Content>
      </Main>
      <Footer>
        <FooterLink to={fLink.to}>{t(fLink.name)}</FooterLink>
      </Footer>
    </>
  );
};

export default ExternalLayout;
