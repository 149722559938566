import { handleActions } from 'redux-actions';
import { props } from 'ramda';

import { updateToken } from 'api';

import { AUTHENTICATE, LOGOUT, UPDATE_USER, SET_VIEW } from './types';
import { AUTH, LOGGED_IN, USER, TOKEN, STATUS, ID, VIEW } from '.';

const getInit = () => ({
  [AUTH]: Boolean(sessionStorage[TOKEN]),
  [LOGGED_IN]: false,
  [USER]: {},
  [VIEW]: USER,
});

export default handleActions(
  {
    [AUTHENTICATE]: (state, { payload }) => {
      const [token, status, id] = props([TOKEN, STATUS, ID], payload || {});

      updateToken(token);

      return {
        ...state,
        [AUTH]: Boolean(token),
        [LOGGED_IN]: Boolean(token && id) && status === 1,
        [USER]: payload,
      };
    },
    [LOGOUT]: () => {
      updateToken();

      return getInit();
    },
    [UPDATE_USER]: (state, { payload }) => ({
      ...state,
      [USER]: { ...state[USER], ...payload },
    }),
    [SET_VIEW]: (state, { payload }) => ({ ...state, [VIEW]: payload }),
  },
  getInit()
);
