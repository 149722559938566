import styled, { createGlobalStyle } from 'styled-components';

import { ReactComponent as Arrow } from './arrow.svg';

export const GlobalStyles = createGlobalStyle`
  #root {
    width: calc(100% - 400px);
    background-color: var(--color-body);
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  inset: 0 0 auto 0;
  height: var(--header-height);
  border-bottom: 1px solid var(--color-grey34);
  background-color: var(--color-body);
  font: var(--font22);
  color: var(--color-black);
  padding: 0 15px;
  z-index: 10;
`;

export const Main = styled.main`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 25px 30px;
`;

export const Footer = styled.footer`
  flex: 0 1 auto;
  display: flex;
  flex-direction: ${({ $isReversed }) => ($isReversed ? 'row-reverse' : 'row')};
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
  border-top: 1px solid var(--color-grey34);
  background-color: var(--color-body);
  padding: 0 30px;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: ${({ $isReversed }) => ($isReversed ? 'row-reverse' : 'row')};
  align-items: center;
  gap: 8px;
  &:after {
    content: '${({ title }) => title}';
    font: var(--font22);
    color: var(--color-black);
  }
`;

export const ArrowIcon = styled(Arrow)`
  transform: rotate(${({ $isReversed }) => ($isReversed ? 180 : 0)}deg);
`;
