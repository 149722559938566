import { combineReducers } from 'redux';

import { FEATURE_NAME as alerts } from 'store/alerts/consts';
import alertsReducer from 'store/alerts/reducer';

import { FEATURE_NAME as session } from 'store/session/consts';
import sessionReducer from 'store/session/reducer';

import { FEATURE_NAME as attributes } from 'store/attributes/consts';
import attributesReducer from 'store/attributes/reducer';

export default combineReducers({
  [alerts]: alertsReducer,
  [session]: sessionReducer,
  [attributes]: attributesReducer,
});
