import { wrapActions, wrapSelector, wrapMultiSelector } from 'store/utils';

import * as actions from './actions';
import {
  getStep,
  getAge,
  getGender,
  getAttributes,
  getAttribute,
  getResult,
  getResultByScore,
  getFilter,
  getFilters,
} from './selectors';

export const useAttributesActions = wrapActions({ ...actions });

export const useStep = wrapSelector(getStep);
export const useAge = wrapSelector(getAge);
export const useGender = wrapSelector(getGender);
export const useAttributes = wrapMultiSelector(getAttribute, getAttributes);
export const useResult = wrapMultiSelector(getResultByScore, getResult);
export const useFilters = wrapMultiSelector(getFilter, getFilters);
