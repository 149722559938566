import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  AUTHENTICATE,
  LOGOUT,
  UPDATE_USER,
  SET_VIEW,
  LOGIN,
  RESTORE,
  FETCH_RESET_LINK,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  SAVE_USER,
  SEND_CONTACT,
} from './types';

export const authenticate = createAction(AUTHENTICATE);
export const logout = createAction(LOGOUT);
export const updateUser = createAction(UPDATE_USER);
export const setView = createAction(SET_VIEW);

export const login = createAlertAction(LOGIN);
export const restore = createAlertAction(RESTORE);
export const fetchResetLink = createAlertAction(FETCH_RESET_LINK);
export const resetPassword = createAlertAction(RESET_PASSWORD);
export const updatePassword = createAlertAction(UPDATE_PASSWORD);
export const saveUser = createAlertAction(SAVE_USER);
export const sendContact = createAlertAction(SEND_CONTACT);
