import axiosDef from 'axios';
import { omit } from 'ramda';

import { TOKEN, DEFAULT_ERROR } from './consts';

export { TOKEN };

export const axios = axiosDef.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'api-key': process.env.REACT_APP_TOKEN,
    ...(sessionStorage[TOKEN] ? { Authorization: `Bearer ${sessionStorage[TOKEN]}` } : {}),
  },
  timeout: 60000,
  validateStatus: (status) => (status >= 200 && status < 300) || status === 400 || status === 401 || status === 403,
});

export const updateToken = (token) => {
  if (token) {
    sessionStorage.setItem(TOKEN, token);
    axios.defaults.headers = {
      ...axios.defaults.headers,
      Authorization: `Bearer ${token}`,
    };

    return;
  }

  sessionStorage.removeItem(TOKEN);
  axios.defaults.headers = omit(['Authorization'], axios.defaults.headers);
};

const handleResponse = ({ status, data }) => {
  if (status === 401) {
    updateToken();
    window.location.reload();

    return data;
  }
  if (status < 200 || status >= 300) {
    const error = new Error(data?.error || DEFAULT_ERROR);
    error.status = status;
    throw error;
  }

  return data;
};

const handler = async (promise, ...args) => {
  try {
    return handleResponse(await promise(...args));
  } catch (e) {
    if (e?.status) throw Error(e?.message);

    throw Error('Abruf fehlgeschlagen');
  }
};

const methods = {
  get: (...args) => handler(axios.get, ...args),
  post: (...args) => handler(axios.post, ...args),
  put: (...args) => handler(axios.put, ...args),
  patch: (...args) => handler(axios.patch, ...args),
  delete: (...args) => handler(axios.delete, ...args),
};

export default methods;
