import React from 'react';
import PropTypes from 'prop-types';

import { Container, Loader } from './styles';

const Spinner = ({ size, color, className }) => (
  <Container className={className}>
    <Loader $size={size} $color={color} />
  </Container>
);

Spinner.defaultProps = {
  size: 50,
  color: 'var(--color-black)',
  className: null,
};
Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Spinner;
