import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  SET_ATTRIBUTES,
  SET_FILTERS,
  TOGGLE_ATTRIBUTE,
  RESET,
  FETCH_ALL,
  CREATE,
  FETCH,
  SAVE,
  CREATE_SCORE,
  FETCH_STACKS,
  FETCH_STACK,
} from './types';

export const setAttributes = createAction(SET_ATTRIBUTES);
export const setFilters = createAction(SET_FILTERS);
export const toggleAttribute = createAction(TOGGLE_ATTRIBUTE);
export const reset = createAction(RESET);

export const fetchAttributes = createAlertAction(FETCH_ALL);
export const createAttribute = createAlertAction(CREATE);
export const fetchAttribute = createAlertAction(FETCH);
export const saveAttribute = createAlertAction(SAVE);
export const createScore = createAlertAction(CREATE_SCORE);
export const fetchStacks = createAlertAction(FETCH_STACKS);
export const fetchStack = createAlertAction(FETCH_STACK);
