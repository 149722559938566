import { handleActions } from 'redux-actions';
import { hasPath, omit } from 'ramda';

import { LOGOUT } from 'store/session/types';

import { SET_ATTRIBUTES, SET_FILTERS, TOGGLE_ATTRIBUTE, RESET } from './types';
import { STEP, AGE, GENDER, LIST, RESULT, FILTERS, HITS, SHOW_STRONG, SHOW_COMMON, ID } from '.';

const init = {
  [STEP]: 0,
  [AGE]: '',
  [GENDER]: '',
  [LIST]: {},
  [FILTERS]: {
    [HITS]: '',
    [SHOW_STRONG]: false,
    [SHOW_COMMON]: false,
  },
  [RESULT]: null,
};

export default handleActions(
  {
    [SET_ATTRIBUTES]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_FILTERS]: (state, { payload }) => ({
      ...state,
      [FILTERS]: payload ? { ...state[FILTERS], ...payload } : init[FILTERS],
    }),
    [TOGGLE_ATTRIBUTE]: (state, { payload }) => {
      const id = payload[ID];

      if (!id) return state;

      return {
        ...state,
        [LIST]: hasPath([LIST, id], state) ? omit([id], state[LIST]) : { [id]: payload, ...state[LIST] },
      };
    },
    [RESET]: () => init,
    [LOGOUT]: () => init,
  },
  init
);
